export const footerStyles = {
  bg: "onPrimaryFixed",
  color: "white",
  p: 3,
  boxShadow: "0px -2px 2px rgba(0, 0, 0, 0.125)",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "baseline",
  justifyContent: "space-evenly",
}

export const dividerStyles = {
  color: "white",
  width: "95%",
  marginTop: "0",
  marginBottom: 2,
}

export const boxStyles = {
  textAlign: "center",
  width: ["100%", "25%"],
  marginBottom: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  color: "white",
}

export const headingStyles = {
  color: "white",
}

export const logoStyles = { width: "200px", marginY: 2 }

export const socialLinksBoxStyles = {
  display: "flex",
  justifyContent: "center",
  p: 2,
  color: "white",
}

export const socialLinkStyles = {
  p: 1,
  color: "white",
}

export const siteMapLinkStyles = {
  color: "white",
  my: 2,
  p: 2,
  backgroundColor: "transparent",
  borderRadius: "6px",
  width: "100%",
}

export const hoursTextStyles = {
  width: "100%",
  color: "white",
}

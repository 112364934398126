import { CommonColors } from "./commonColors"

export const lightTheme = {
  colors: {
    ...CommonColors,
    primary: "var(--md-sys-color-primary-light)",
    onPrimary: "var(--md-sys-color-on-primary-light)",
    primaryContainer: "var(--md-sys-color-primary-container-light)",
    onPrimaryContainer: "var(--md-sys-color-on-primary-container-light)",
    primaryFixed: "var(--md-sys-color-primary-fixed-light)",
    onPrimaryFixed: "var(--md-sys-color-on-primary-fixed-light)",
    primaryFixedDim: "var(--md-sys-color-primary-fixed-dim-light)",
    onPrimaryFixedVariant: "var(--md-sys-color-on-primary-fixed-variant-light)",
    secondary: "var(--md-sys-color-secondary-light)",
    onSecondary: "var(--md-sys-color-on-secondary-light)",
    secondaryContainer: "var(--md-sys-color-secondary-container-light)",
    onSecondaryContainer: "var(--md-sys-color-on-secondary-container-light)",
    secondaryFixed: "var(--md-sys-color-secondary-fixed-light)",
    onSecondaryFixed: "var(--md-sys-color-on-secondary-fixed-light)",
    secondaryFixedDim: "var(--md-sys-color-secondary-fixed-dim-light)",
    onSecondaryFixedVariant:
      "var(--md-sys-color-on-secondary-fixed-variant-light)",
    tertiary: "var(--md-sys-color-tertiary-light)",
    onTertiary: "var(--md-sys-color-on-tertiary-light)",
    tertiaryContainer: "var(--md-sys-color-tertiary-container-light)",
    onTertiaryContainer: "var(--md-sys-color-on-tertiary-container-light)",
    tertiaryFixed: "var(--md-sys-color-tertiary-fixed-light)",
    onTertiaryFixed: "var(--md-sys-color-on-tertiary-fixed-light)",
    tertiaryFixedDim: "var(--md-sys-color-tertiary-fixed-dim-light)",
    onTertiaryFixedVariant:
      "var(--md-sys-color-on-tertiary-fixed-variant-light)",
    error: "var(--md-sys-color-error-light)",
    onError: "var(--md-sys-color-on-error-light)",
    errorContainer: "var(--md-sys-color-error-container-light)",
    onErrorContainer: "var(--md-sys-color-on-error-container-light)",
    outline: "var(--md-sys-color-outline-light)",
    background: "var(--md-sys-color-background-light)",
    onBackground: "var(--md-sys-color-on-background-light)",
    surface: "var(--md-sys-color-surface-light)",
    onSurface: "var(--md-sys-color-on-surface-light)",
    surfaceVariant: "var(--md-sys-color-surface-variant-light)",
    onSurfaceVariant: "var(--md-sys-color-on-surface-variant-light)",
    inverseSurface: "var(--md-sys-color-inverse-surface-light)",
    inverseOnSurface: "var(--md-sys-color-inverse-on-surface-light)",
    inversePrimary: "var(--md-sys-color-inverse-primary-light)",
    shadow: CommonColors.shadowLight,
    surfaceTint: "var(--md-sys-color-surface-tint-light)",
    outlineVariant: "var(--md-sys-color-outline-variant-light)",
    scrim: "var(--md-sys-color-scrim-light)",
    surfaceContainerHighest:
      "var(--md-sys-color-surface-container-highest-light)",
    surfaceContainerHigh: "var(--md-sys-color-surface-container-high-light)",
    surfaceContainer: "var(--md-sys-color-surface-container-light)",
    surfaceContainerLow: "var(--md-sys-color-surface-container-low-light)",
    surfaceContainerLowest:
      "var(--md-sys-color-surface-container-lowest-light)",
    surfaceBright: "var(--md-sys-color-surface-bright-light)",
    surfaceDim: "var(--md-sys-color-surface-dim-light)",
    transparent: "rgba(255,255,255,0.08)",
    labelPrimary: "#025CBCBD",
  },
}

export const darkTheme = {
  colors: {
    ...CommonColors,
    primary: "var(--md-sys-color-primary-dark)",
    onPrimary: "var(--md-sys-color-on-primary-dark)",
    primaryContainer: "var(--md-sys-color-primary-container-dark)",
    onPrimaryContainer: "var(--md-sys-color-on-primary-container-dark)",
    primaryFixed: "var(--md-sys-color-primary-fixed-dark)",
    onPrimaryFixed: "var(--md-sys-color-on-primary-fixed-dark)",
    primaryFixedDim: "var(--md-sys-color-primary-fixed-dim-dark)",
    onPrimaryFixedVariant: "var(--md-sys-color-on-primary-fixed-variant-dark)",
    secondary: "var(--md-sys-color-secondary-dark)",
    onSecondary: "var(--md-sys-color-on-secondary-dark)",
    secondaryContainer: "var(--md-sys-color-secondary-container-dark)",
    onSecondaryContainer: "var(--md-sys-color-on-secondary-container-dark)",
    secondaryFixed: "var(--md-sys-color-secondary-fixed-dark)",
    onSecondaryFixed: "var(--md-sys-color-on-secondary-fixed-dark)",
    secondaryFixedDim: "var(--md-sys-color-secondary-fixed-dim-dark)",
    onSecondaryFixedVariant:
      "var(--md-sys-color-on-secondary-fixed-variant-dark)",
    tertiary: "var(--md-sys-color-tertiary-dark)",
    onTertiary: "var(--md-sys-color-on-tertiary-dark)",
    tertiaryContainer: "var(--md-sys-color-tertiary-container-dark)",
    onTertiaryContainer: "var(--md-sys-color-on-tertiary-container-dark)",
    tertiaryFixed: "var(--md-sys-color-tertiary-fixed-dark)",
    onTertiaryFixed: "var(--md-sys-color-on-tertiary-fixed-dark)",
    tertiaryFixedDim: "var(--md-sys-color-tertiary-fixed-dim-dark)",
    onTertiaryFixedVariant:
      "var(--md-sys-color-on-tertiary-fixed-variant-dark)",
    error: "var(--md-sys-color-error-dark)",
    onError: "var(--md-sys-color-on-error-dark)",
    errorContainer: "var(--md-sys-color-error-container-dark)",
    onErrorContainer: "var(--md-sys-color-on-error-container-dark)",
    outline: "var(--md-sys-color-outline-dark)",
    background: "var(--md-sys-color-background-dark)",
    onBackground: "var(--md-sys-color-on-background-dark)",
    surface: "var(--md-sys-color-surface-dark)",
    onSurface: "var(--md-sys-color-on-surface-dark)",
    surfaceVariant: "var(--md-sys-color-surface-variant-dark)",
    onSurfaceVariant: "var(--md-sys-color-on-surface-variant-dark)",
    inverseSurface: "var(--md-sys-color-inverse-surface-dark)",
    inverseOnSurface: "var(--md-sys-color-inverse-on-surface-dark)",
    inversePrimary: "var(--md-sys-color-inverse-primary-dark)",
    shadow: CommonColors.shadowDark,
    surfaceTint: "var(--md-sys-color-surface-tint-dark)",
    outlineVariant: "var(--md-sys-color-outline-variant-dark)",
    scrim: "var(--md-sys-color-scrim-dark)",
    surfaceContainerHighest:
      "var(--md-sys-color-surface-container-highest-dark)",
    surfaceContainerHigh: "var(--md-sys-color-surface-container-high-dark)",
    surfaceContainer: "var(--md-sys-color-surface-container-dark)",
    surfaceContainerLow: "var(--md-sys-color-surface-container-low-dark)",
    surfaceContainerLowest: "var(--md-sys-color-surface-container-lowest-dark)",
    surfaceBright: "var(--md-sys-color-surface-bright-dark)",
    surfaceDim: "var(--md-sys-color-surface-dim-dark)",
    transparent: "rgba(29,25,43,0.08)",
    labelPrimary: "#025CBCBD",
  },
}
